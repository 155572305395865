import ChallengeModel from '@/app/models/challenges/ChallengeModel';
import { challengeService } from '@/app/service/challengeService/challengeService';
import { myChannelService } from '@/app/service/myChannelService';
import { logger } from '@/app/lib/logger';
import utilsService from '@/app/helpers/utilsService';
import { PostModel } from '@/app/models/posts/Posts';
import { postsService } from '@/app/service/postsService/postsService';
import { homeService } from '@/app/service/homeService';

enum FetchMethod {
    challenges = 'challenges',
    posts = 'posts',
    fetchForMyChannel = 'fetchForMyChannel',
    fetchForHome = 'fetchForHome',
    fetchForPartner = 'fetchForPartner',
}

interface FetchFeeds {
    fetchDataForFeed<T>(method: FetchMethod, orderData: any, cursor: string | null, signal: AbortSignal): Promise<{ data: T[]; total: number }>;
}

interface paramsManager {
    orderData?: any;
    cursor?: string | null;
    perPage?: number;
    signal?: AbortSignal;
}

type ServiceFunction = (params: any) => Promise<any>;
type DataHandler = (data: any) => any;

interface FetchMethodConfig {
    serviceFunction: ServiceFunction;
    model: new (data: any) => any;
    params: ({ orderData, cursor, perPage, signal }: paramsManager) => any;
    dataHandler?: DataHandler;
}

const fetchMethodConfigMap: Record<FetchMethod, FetchMethodConfig> = {
    [FetchMethod.challenges]: {
        serviceFunction: challengeService.GetListChallenges,
        model: ChallengeModel,
        params: ({ orderData, cursor, perPage, signal }) => ({ type: orderData.type, condition: orderData.filter, cursor, perPage, signal }),
    },
    [FetchMethod.posts]: {
        serviceFunction: postsService.getPostLists,
        model: PostModel,
        params: ({ orderData, cursor, perPage, signal }) => ({ condition: orderData?.filter, cursor, perPage, signal }),
    },
    [FetchMethod.fetchForMyChannel]: {
        serviceFunction: myChannelService.filterFeedsInMyChannelPage,
        model: PostModel,
        params: ({ orderData, cursor, perPage, signal }) => ({ filter: orderData.filter, cursor, perPage, signal }),
        dataHandler: (data: PostModel[]) => {
            return data.map((item: any) => {
                if (item.post) {
                    return new PostModel(item.post);
                }
                return new PostModel(item);
            });
        },
    },
    [FetchMethod.fetchForPartner]: {
        serviceFunction: myChannelService.filterFeedsInMyChannelForPartnerPage,
        model: PostModel,
        params: ({ orderData, cursor, perPage, signal }) => ({ groupId: orderData?.groupId, partnerId: orderData.partnerId, filter: orderData.filter, cursor, perPage, signal }),
        dataHandler: (data: PostModel[]) => {
            return data.map((item: any) => {
                if (item.post) {
                    return new PostModel(item.post);
                }
                return new PostModel(item);
            });
        },
    },
    [FetchMethod.fetchForHome]: {
        serviceFunction: homeService.getTopPosts,
        model: PostModel,
        params: ({ orderData, cursor, perPage, signal }) => ({ limit: 10, filter: orderData.filter, cursor, perPage, signal }),
        dataHandler: (data: PostModel[]) => {
            return data.map((item: any) => {
                if (item.post) {
                    return new PostModel(item.post);
                }
                return new PostModel(item);
            });
        },
    },
};

class FeedManager implements FetchFeeds {
    private perPage: number;

    constructor(perPage: number = 20) {
        this.perPage = perPage;
    }

    async fetchDataForFeed<T>(method: FetchMethod, orderData: any, cursor: string | null, signal: AbortSignal): Promise<{ data: T[]; cursor: string | null; total: number }> {
        try {
            const { serviceFunction, model, params, dataHandler } = fetchMethodConfigMap[method];
            const response = await serviceFunction(params({ orderData, cursor, perPage: this.perPage, signal }));
            const totalFeeds = response.total || 0;
            const cursorResponse = response.cursor ?? null;
            const processedData = dataHandler ? dataHandler(response.data) : response?.data?.map((item: any) => new model(item));
            return {
                data: processedData,
                total: totalFeeds,
                cursor: cursorResponse,
            };
        } catch (error: any) {
            const getMessage = utilsService.getErrorMessage(error);
            if (getMessage !== 'canceled') {
                logger.error(error);
            }
            throw error;
        }
    }
}

export { FeedManager, FetchMethod };
