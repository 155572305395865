'use client';
import React, { createContext, useContext, useState, useRef, useEffect, ReactNode, useCallback } from 'react';
import { useSnackbar } from '@/app/contexts/snack-bar-provider';
import utilsService from '@/app/helpers/utilsService';
import { actionFeedServices } from '@/app/service/actionFeedService';
import { PostTypeConfig } from '@/app/constants/typedef';
import { FeedManager, FetchMethod } from '../pattern/ClassManagerMethodPost';
import { PostModel } from '../models/posts/Posts';
import { useParams } from 'next/navigation';

interface PostContextProps {
    posts: any[];
    isLoading: boolean;
    hasNext: boolean;
    totalPosts: number;
    hasError: boolean;
    setFilter: (filter: string) => void;
    setType: (type: PostTypeConfig) => void;
    loadMore: () => Promise<void>;
    hiddenPost: (id: string) => void;
    deletePost: (id: string) => void;
    updatePost: (updatedPost: PostModel) => void;
    addPost: (newPost: PostModel) => void;
    updatePostField: (postId: string, field: keyof PostModel, newValue: any) => void;
    onRetryWhenHasError: () => void;
}

const PostContext = createContext<PostContextProps | undefined>(undefined);

export const usePostContext = () => {
    const context = useContext(PostContext);
    if (!context) {
        throw new Error('usePostContext must be used within a PostProvider');
    }
    return context;
};

export const PostProvider = ({ children }: { children: ReactNode }) => {
    const [posts, setPosts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [cursor, setCursor] = useState<string | null>(null);
    const { id: partnerId } = useParams();
    const [filter, setFilter] = useState<string>('');
    const [type, setType] = useState<PostTypeConfig | null>(null);
    const totalPosts = useRef<number>(0);
    const isFetching = useRef<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const feedManager = useRef(new FeedManager(20)).current;
    const fetchController = useRef<AbortController | null>(null);
    const { addSnackbar, setMarginBottom } = useSnackbar();

    useEffect(() => {
        setMarginBottom(80);
    }, []);

    const fetchPosts = async (filter: string, reset?: boolean) => {
        if (hasError) return;

        if (fetchController.current) {
            fetchController.current.abort();
        }
        fetchController.current = new AbortController();
        const signal = fetchController.current.signal;
        isFetching.current = true;
        try {
            setIsLoading(true);
            const method = getFetchMethod(type);
            const { data, total, cursor: cur } = await feedManager.fetchDataForFeed<any>(method, { type, filter, partnerId }, reset ? null : cursor, signal);
            totalPosts.current = total;
            setCursor(cur ?? null);
            setPosts((prevPosts) => (reset ? data : [...prevPosts, ...data]));
            setIsLoading(false);
            isFetching.current = false;
            setHasError(false);
        } catch (error: any) {
            const getMessage = utilsService.getErrorMessage(error);
            if (getMessage !== 'canceled') {
                setIsLoading(false);
                isFetching.current = false;
                setHasError(true);
                addSnackbar({
                    message: getMessage || 'Some thing went wrong',
                    severity: 'error',
                    autoHideDuration: 3000,
                    vertical: 'bottom',
                    horizontal: 'center',
                    type: 'toast',
                });
                return;
            }
        }
    };

    const getFetchMethod = (type: string | null): FetchMethod => {
        switch (type) {
            case PostTypeConfig.Challenges:
                return FetchMethod.challenges;
            case PostTypeConfig.Posts:
                return FetchMethod.posts;
            case PostTypeConfig.fetchForMyChannel:
                return FetchMethod.fetchForMyChannel;
            case PostTypeConfig.fetchForPartner:
                return FetchMethod.fetchForPartner;
            default:
                throw new Error('Unknown filter');
        }
    };

    const hiddenPost = async (id: string) => {
        try {
            await setPosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
        } catch (error) {
            const errorMessage = utilsService.getErrorMessage(error);
            addSnackbar({
                message: errorMessage || 'Some thing went wrong',
                severity: 'error',
                autoHideDuration: 3000,
                vertical: 'bottom',
                horizontal: 'center',
                type: 'toast',
            });
        }
    };

    const deletePost = async (id: string) => {
        try {
            await actionFeedServices.deleteFeedForOnlyPosts({ type: 'posts', id });
            setPosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
            addSnackbar({
                message: '게시물을 삭제했어요.',
                severity: 'info',
                autoHideDuration: 3000,
                vertical: 'bottom',
                horizontal: 'center',
                type: 'toast',
            });
        } catch (error: any) {
            const errorMessage = utilsService.getErrorMessage(error);
            addSnackbar({
                message: errorMessage || 'Some thing went wrong',
                severity: 'error',
                autoHideDuration: 3000,
                vertical: 'bottom',
                horizontal: 'center',
                type: 'toast',
            });
        }
    };

    const updatePost = (updatedPost: any) => {
        setPosts((prevPosts) => prevPosts.map((post) => (post.id === updatedPost.id ? updatedPost : post)));
    };

    const addPost = (newPost: any) => {
        const currentType = type;
        if (currentType !== null && [PostTypeConfig.fetchForMyChannel, PostTypeConfig.fetchForPartner, PostTypeConfig.Posts].includes(currentType)) {
            validateAddPost(newPost);
        }
    };

    const validateAddPost = (newPost: any) => {
        if (type && ['fetchForMyChannel'].includes(type) && ['comments', 'likes', 'bookmarks'].includes(filter)) return; //do nothing
        setPosts((prevPosts) => [newPost, ...prevPosts]);
    };

    const loadMore = useCallback(async () => {
        if (cursor && !isLoading && !isFetching.current && !hasError) {
            await fetchPosts(filter);
        }
    }, [posts, cursor, isLoading, hasError]);

    const onRetryWhenHasError = useCallback(() => {
        setCursor(null);
        setPosts([]);
    }, [filter, type]);

    const updatePostField = (postId: string, field: keyof PostModel, newValue: any) => {
        setPosts((prevPosts) =>
            prevPosts.map((post) => {
                if (post.id === postId) {
                    const updatedPost = { ...post };
                    updatedPost[field] = newValue;
                    return Object.assign(Object.create(Object.getPrototypeOf(post)), updatedPost);
                }
                return post;
            })
        );
    };

    useEffect(() => {
        if (filter && type) {
            setCursor(null);
            setPosts([]);
            fetchPosts(filter, true);
        }
    }, [filter, type]);

    const hasNext = posts.length < totalPosts.current && !hasError;
    const value = {
        posts,
        isLoading,
        hasNext,
        hasError,
        setFilter,
        totalPosts: totalPosts.current,
        setType,
        loadMore,
        deletePost,
        hiddenPost,
        updatePost,
        addPost,
        updatePostField,
        onRetryWhenHasError,
    };

    return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};
