'use client';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Fade, Snackbar, SnackbarCloseReason, SnackbarContent, Typography } from '@mui/material';
import { BaseAlert } from '../base-ui';
import { useTheme } from '@mui/material/styles';
import { MAX_SCREEN_WIDTH } from '../constants/layoutConfig';

interface SnackbarMessage {
    message: string;
    severity?: 'info' | 'success' | 'warning' | 'error';
    key?: number;
    autoHideDuration?: number;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'right' | 'center';
    type?: 'toast' | 'alert';
}

interface SnackbarContextType {
    addSnackbar: (snackbar: SnackbarMessage) => void;
    marginBottom: number;
    setMarginBottom: React.Dispatch<React.SetStateAction<number>>;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

interface SnackbarProviderProps {
    children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
    const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [marginBottom, setMarginBottom] = useState(20);
    const theme = useTheme();

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
            // Close an active snackbar before displaying the next one
            setOpen(false);
        }
    }, [snackPack, messageInfo, open]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    const addSnackbar = (snackbar: SnackbarMessage) => {
        setSnackPack((prev) => [...prev, { ...snackbar, key: snackbar.key || new Date().getTime() }]);
    };

    return (
        <SnackbarContext.Provider value={{ addSnackbar, marginBottom, setMarginBottom }}>
            {children}
            {messageInfo && (
                <Snackbar
                    key={messageInfo.key}
                    disableWindowBlurListener
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                        vertical: messageInfo?.vertical ?? 'bottom',
                        horizontal: messageInfo?.horizontal ?? 'center',
                    }}
                    autoHideDuration={messageInfo.autoHideDuration ?? 3000}
                    TransitionProps={{ onExited: handleExited }}
                    sx={{
                        '@media (min-width:600px)': {
                            width: '95%',
                            maxWidth: 'calc(768px - 50px)',
                        },
                        color: '#fff',
                        background: 'rgba(0, 0, 0, 0.60)',
                        margin: '0 auto',
                        fontSize: theme.typography.h2_12Regular,
                        borderRadius: 4,
                        padding: '11px 26px 11px 14px',
                        width: 'auto',
                        right: '20px',
                        left: '20px',
                        maxWidth: MAX_SCREEN_WIDTH,
                        mb: `${marginBottom}px`,
                        backdropFilter: 'blur(5px)',
                    }}
                >
                    <SnackbarContent
                        sx={{
                            '.MuiSnackbarContent-message': {
                                py: 0,
                                px: 0,
                            },
                            fontSize: '12px',
                            fontWeight: 400,
                            width: '100%',
                            color: '#fff',
                            background: 'none',
                            boxShadow: 'none',
                            py: 0,
                            px: 0,
                        }}
                        message={<Typography variant="h2_12Regular">{messageInfo.message}</Typography>}
                    />
                </Snackbar>
            )}
        </SnackbarContext.Provider>
    );
};

export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext);
    if (context === undefined) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};
